*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

header{
  background-color: #262626;
}

li{
  list-style: none;
}

a{
  color: white;
  text-decoration: none;
}

.navbar{
  min-height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  z-index:2;
}

.navbranding{
  font-size: 2rem;
}

.navmenu{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 60px;
  z-index: 2;
}

.navlink{
  transition: 0.3s ease-out;
}

.navlink:hover{
  color: dodgerblue;
}

.hamburger{
  display: none;
  cursor: pointer;
}

.bar{
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-color: white;
}

@media(max-width:1100px){
  .hamburger{
    display: block;
  }

  .hamburger.active .bar:nth-child(2){
    opacity: 0;
  }
  .hamburger.active .bar:nth-child(1){
    transform: translateY(8px) rotate(45deg);
  }
  .hamburger.active .bar:nth-child(3){
    transform: translateY(-8px) rotate(-45deg);
  }

  .navmenu{
    position: fixed;
    left: -100%;
    top: 70px;
    gap: 0;
    flex-direction: column;
    background-color: #262626;
    width: 100%;
    text-align: center;
    transition: 0.3s;
  }

  .navitem{
    margin: 16px 0;
  }

  .navmenu.active{
    left: 0;
  }
}