.goodBye{
    display: flex;
    flex-direction: column;
    justify-content: normal;
    align-items: center;
    background-color: #282c34;
    min-height: 100vmin;
    padding: 20px;
    @media (max-width: 500px){
        min-height: 100vmax;
    }

}

.pic{
    padding: 20px;
    height: 60vmin;
    @media (max-width: 500px) {
        height: min-content;
        width: 90vmin;
    }
}

.thankYou{
    color: white;
}

.paragraph{
    color: white;
    padding: 20px;

}