.App {
  text-align: center;
  min-height: calc(100% + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}



.App-logo {
  height: 60vmin;
  pointer-events: none;
  position: relative;
  @media (max-width: 500px) {
    height: 100vmin;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    &hover{
      animation: App-logo-spin infinite 1s linear;
    }
  }
 
}

.App-header {
  cursor: url('img/pogp.jpg'), auto;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10vh;
  font-size: calc(10px + 2vmin);
  max-width: 100vw;
  color: white;
  overflow: hidden;
  p { 
    margin-top: 60px;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
