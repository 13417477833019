.error{
    background-color: black;
    color:white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1 {
        font-size: calc(100px + 2vmin);
        animation: blinker .5s linear infinite; 
        @keyframes blinker {
            50% {
              opacity: 0;
            }
        }
    }
    
}

